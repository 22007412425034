import validate from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_77pcj3ski7647iwmjg6r37rpcm/node_modules/nuxt/dist/pages/runtime/validate.js";
import location_45global from "/home/runner/work/web/web/middleware/location.global.ts";
import redirect_45global from "/home/runner/work/web/web/middleware/redirect.global.ts";
import manifest_45route_45rule from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_77pcj3ski7647iwmjg6r37rpcm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  location_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}