import revive_payload_client_hgRJN9nAar from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_77pcj3ski7647iwmjg6r37rpcm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_R05ILpjbYA from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_77pcj3ski7647iwmjg6r37rpcm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6Q5WazuKws from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_77pcj3ski7647iwmjg6r37rpcm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_4SdeHFmP7I from "/home/runner/work/web/web/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.3_@unocss+reset@0.60.4_async-validator@4.2.5_axios_ndntps67y4bktjh5zhnug2kcvm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_Mlwz3v2LGK from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_77pcj3ski7647iwmjg6r37rpcm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_C98TDw7Bx7 from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_77pcj3ski7647iwmjg6r37rpcm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web/web/.nuxt/components.plugin.mjs";
import prefetch_client_6vFCNqVV4T from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_77pcj3ski7647iwmjg6r37rpcm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_H5qCIBLMQ9 from "/home/runner/work/web/web/node_modules/.pnpm/@vueuse+motion@2.2.3_rollup@4.18.0_vue@3.4.27_typescript@5.5.3_/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import chunk_reload_client_zY0vS8jge4 from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_77pcj3ski7647iwmjg6r37rpcm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_datadog_client_kyKOfu6LUJ from "/home/runner/work/web/web/plugins/01.datadog.client.ts";
import _02_customFetch_client_Wfsz81JW0D from "/home/runner/work/web/web/plugins/02.customFetch.client.ts";
import firebase_client_zXNDa0wxFH from "/home/runner/work/web/web/plugins/firebase.client.ts";
import gesture_4SIjAfPLV5 from "/home/runner/work/web/web/plugins/gesture.ts";
export default [
  revive_payload_client_hgRJN9nAar,
  unhead_R05ILpjbYA,
  router_6Q5WazuKws,
  _0_siteConfig_4SdeHFmP7I,
  payload_client_Mlwz3v2LGK,
  check_outdated_build_client_C98TDw7Bx7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6vFCNqVV4T,
  motion_H5qCIBLMQ9,
  chunk_reload_client_zY0vS8jge4,
  _01_datadog_client_kyKOfu6LUJ,
  _02_customFetch_client_Wfsz81JW0D,
  firebase_client_zXNDa0wxFH,
  gesture_4SIjAfPLV5
]