export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"https://static.myproviders.org/static/cf60bc3bd40a353850bd67256018f5c58b9058e3/favicon.png"}],"style":[],"script":[{"id":"cookieyes","src":"https://cdn-cookieyes.com/client_data/14c45d1fd45a225e13e917a2/script.js","type":"text/javascript"}],"noscript":[]}

export const appCdnURL = "https://static.myproviders.org/static/cf60bc3bd40a353850bd67256018f5c58b9058e3"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false