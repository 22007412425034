<script setup lang="ts">
const props = defineProps({
  error: Object,
});

const handleError = () => clearError({ redirect: '/' });
useHead({
  title: `${props.error.statusCode} - MyProviders`,
  meta: [
    {
      name: 'description',
      content: 'Error page',
    },
  ],
});
const showErrorDetail = process.env.NODE_ENV === 'production';
</script>

<template>
  <main
    class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
  >
    <div class="text-center">
      <a href="/">
        <img
          class="mx-auto h-12 w-21 w-auto mb-5"
          src="/logo.png"
          alt="MyProviders"
        />
      </a>
      <p class="text-base font-semibold">{{ props.error.statusMessage }}</p>
      <p class="mt-6 text-base leading-7 text-gray-600">
        Sorry, we couldn't display the page you are looking for.
      </p>
      <p>{{ showErrorDetail ? '' : props.error }}</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <a href="/" class="btn">Go back home</a>
        <a href="/contact" class="text-sm font-semibold text-gray-900"
          >Contact support <span aria-hidden="true">&rarr;</span></a
        >
      </div>
    </div>
  </main>
</template>
