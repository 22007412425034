import { default as aboutDdLcFtJL1NMeta } from "/home/runner/work/web/web/pages/about.vue?macro=true";
import { default as addProvider7qzxalN55yMeta } from "/home/runner/work/web/web/pages/addProvider.vue?macro=true";
import { default as appointmentRequested6SgNGVtHIhMeta } from "/home/runner/work/web/web/pages/appointmentRequested.vue?macro=true";
import { default as appointments5j3ceDGuxTMeta } from "/home/runner/work/web/web/pages/appointments.vue?macro=true";
import { default as booking97QRSYclLRMeta } from "/home/runner/work/web/web/pages/booking.vue?macro=true";
import { default as contactzSTaobd69CMeta } from "/home/runner/work/web/web/pages/contact.vue?macro=true";
import { default as addresseLNAb6k6kBMeta } from "/home/runner/work/web/web/pages/create/address.vue?macro=true";
import { default as areaOEKrbZWZxaMeta } from "/home/runner/work/web/web/pages/create/area.vue?macro=true";
import { default as businessvCoq682tYyMeta } from "/home/runner/work/web/web/pages/create/business.vue?macro=true";
import { default as claim1ioY7qkcVaMeta } from "/home/runner/work/web/web/pages/create/claim.vue?macro=true";
import { default as contacthafJqwUntkMeta } from "/home/runner/work/web/web/pages/create/contact.vue?macro=true";
import { default as index5k5elmOxxSMeta } from "/home/runner/work/web/web/pages/create/index.vue?macro=true";
import { default as locationgBv8QVx61OMeta } from "/home/runner/work/web/web/pages/create/location.vue?macro=true";
import { default as index8Wx8VjfYI0Meta } from "/home/runner/work/web/web/pages/index.vue?macro=true";
import { default as landingoBrqhuQ4hqMeta } from "/home/runner/work/web/web/pages/landing.vue?macro=true";
import { default as logintl35qSNwFpMeta } from "/home/runner/work/web/web/pages/login.vue?macro=true";
import { default as calendaru9ET4GOXsvMeta } from "/home/runner/work/web/web/pages/manage/calendar.vue?macro=true";
import { default as clientswP5AVjTiFGMeta } from "/home/runner/work/web/web/pages/manage/clients.vue?macro=true";
import { default as inboxugYUTYRUegMeta } from "/home/runner/work/web/web/pages/manage/inbox.vue?macro=true";
import { default as messagesTXZoIpjTO4Meta } from "/home/runner/work/web/web/pages/manage/messages.vue?macro=true";
import { default as settingst7N6DfIQroMeta } from "/home/runner/work/web/web/pages/manage/settings.vue?macro=true";
import { default as zoomcallbackebhly7jQ9YMeta } from "/home/runner/work/web/web/pages/manage/zoomcallback.vue?macro=true";
import { default as managegQsVP3s34OMeta } from "/home/runner/work/web/web/pages/manage.vue?macro=true";
import { default as new0VrHbNZ9A2Meta } from "/home/runner/work/web/web/pages/new.vue?macro=true";
import { default as calendar2viFIzyXA2Meta } from "/home/runner/work/web/web/pages/onboarding/calendar.vue?macro=true";
import { default as callbacknlOdNu3GKDMeta } from "/home/runner/work/web/web/pages/onboarding/callback.vue?macro=true";
import { default as indexVM2AUiDHNdMeta } from "/home/runner/work/web/web/pages/onboarding/index.vue?macro=true";
import { default as locationjWuLSbMldcMeta } from "/home/runner/work/web/web/pages/onboarding/location.vue?macro=true";
import { default as scheduleURSW8kAypuMeta } from "/home/runner/work/web/web/pages/onboarding/schedule.vue?macro=true";
import { default as sessionleA8zahuYVMeta } from "/home/runner/work/web/web/pages/onboarding/session.vue?macro=true";
import { default as onboardingix0G1syiIUMeta } from "/home/runner/work/web/web/pages/onboarding.vue?macro=true";
import { default as profileemlkVyFT1mMeta } from "/home/runner/work/web/web/pages/profile.vue?macro=true";
import { default as providerjvAE7lb5YnMeta } from "/home/runner/work/web/web/pages/provider.vue?macro=true";
import { default as searchvd7r2lrCVBMeta } from "/home/runner/work/web/web/pages/search.vue?macro=true";
import { default as sitemapMtvTYpiarpMeta } from "/home/runner/work/web/web/pages/sitemap.vue?macro=true";
import { default as thankyouc3wepwCEfJMeta } from "/home/runner/work/web/web/pages/thankyou.vue?macro=true";
import { default as claimsSs9vFGLxA5Meta } from "/home/runner/work/web/web/pages/tools/claims.vue?macro=true";
import { default as providerssnNtFINy6VMeta } from "/home/runner/work/web/web/pages/tools/providers.vue?macro=true";
import { default as usersG0y3Own4RrMeta } from "/home/runner/work/web/web/pages/tools/users.vue?macro=true";
import { default as yourprivacy98sIRprMt3Meta } from "/home/runner/work/web/web/pages/yourprivacy.vue?macro=true";
export default [
  {
    name: aboutDdLcFtJL1NMeta?.name ?? "about",
    path: aboutDdLcFtJL1NMeta?.path ?? "/about",
    meta: aboutDdLcFtJL1NMeta || {},
    alias: aboutDdLcFtJL1NMeta?.alias || [],
    redirect: aboutDdLcFtJL1NMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/about.vue").then(m => m.default || m)
  },
  {
    name: addProvider7qzxalN55yMeta?.name ?? "addProvider",
    path: addProvider7qzxalN55yMeta?.path ?? "/addProvider",
    meta: addProvider7qzxalN55yMeta || {},
    alias: addProvider7qzxalN55yMeta?.alias || [],
    redirect: addProvider7qzxalN55yMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/addProvider.vue").then(m => m.default || m)
  },
  {
    name: appointmentRequested6SgNGVtHIhMeta?.name ?? "appointmentRequested",
    path: appointmentRequested6SgNGVtHIhMeta?.path ?? "/appointmentRequested",
    meta: appointmentRequested6SgNGVtHIhMeta || {},
    alias: appointmentRequested6SgNGVtHIhMeta?.alias || [],
    redirect: appointmentRequested6SgNGVtHIhMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/appointmentRequested.vue").then(m => m.default || m)
  },
  {
    name: appointments5j3ceDGuxTMeta?.name ?? "appointments",
    path: appointments5j3ceDGuxTMeta?.path ?? "/appointments",
    meta: appointments5j3ceDGuxTMeta || {},
    alias: appointments5j3ceDGuxTMeta?.alias || [],
    redirect: appointments5j3ceDGuxTMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/appointments.vue").then(m => m.default || m)
  },
  {
    name: booking97QRSYclLRMeta?.name ?? "booking",
    path: booking97QRSYclLRMeta?.path ?? "/booking",
    meta: booking97QRSYclLRMeta || {},
    alias: booking97QRSYclLRMeta?.alias || [],
    redirect: booking97QRSYclLRMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/booking.vue").then(m => m.default || m)
  },
  {
    name: contactzSTaobd69CMeta?.name ?? "contact",
    path: contactzSTaobd69CMeta?.path ?? "/contact",
    meta: contactzSTaobd69CMeta || {},
    alias: contactzSTaobd69CMeta?.alias || [],
    redirect: contactzSTaobd69CMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: addresseLNAb6k6kBMeta?.name ?? "create-address",
    path: addresseLNAb6k6kBMeta?.path ?? "/create/address",
    meta: addresseLNAb6k6kBMeta || {},
    alias: addresseLNAb6k6kBMeta?.alias || [],
    redirect: addresseLNAb6k6kBMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/create/address.vue").then(m => m.default || m)
  },
  {
    name: areaOEKrbZWZxaMeta?.name ?? "create-area",
    path: areaOEKrbZWZxaMeta?.path ?? "/create/area",
    meta: areaOEKrbZWZxaMeta || {},
    alias: areaOEKrbZWZxaMeta?.alias || [],
    redirect: areaOEKrbZWZxaMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/create/area.vue").then(m => m.default || m)
  },
  {
    name: businessvCoq682tYyMeta?.name ?? "create-business",
    path: businessvCoq682tYyMeta?.path ?? "/create/business",
    meta: businessvCoq682tYyMeta || {},
    alias: businessvCoq682tYyMeta?.alias || [],
    redirect: businessvCoq682tYyMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/create/business.vue").then(m => m.default || m)
  },
  {
    name: claim1ioY7qkcVaMeta?.name ?? "create-claim",
    path: claim1ioY7qkcVaMeta?.path ?? "/create/claim",
    meta: claim1ioY7qkcVaMeta || {},
    alias: claim1ioY7qkcVaMeta?.alias || [],
    redirect: claim1ioY7qkcVaMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/create/claim.vue").then(m => m.default || m)
  },
  {
    name: contacthafJqwUntkMeta?.name ?? "create-contact",
    path: contacthafJqwUntkMeta?.path ?? "/create/contact",
    meta: contacthafJqwUntkMeta || {},
    alias: contacthafJqwUntkMeta?.alias || [],
    redirect: contacthafJqwUntkMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/create/contact.vue").then(m => m.default || m)
  },
  {
    name: index5k5elmOxxSMeta?.name ?? "create",
    path: index5k5elmOxxSMeta?.path ?? "/create",
    meta: index5k5elmOxxSMeta || {},
    alias: index5k5elmOxxSMeta?.alias || [],
    redirect: index5k5elmOxxSMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/create/index.vue").then(m => m.default || m)
  },
  {
    name: locationgBv8QVx61OMeta?.name ?? "create-location",
    path: locationgBv8QVx61OMeta?.path ?? "/create/location",
    meta: locationgBv8QVx61OMeta || {},
    alias: locationgBv8QVx61OMeta?.alias || [],
    redirect: locationgBv8QVx61OMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/create/location.vue").then(m => m.default || m)
  },
  {
    name: index8Wx8VjfYI0Meta?.name ?? "index",
    path: index8Wx8VjfYI0Meta?.path ?? "/",
    meta: index8Wx8VjfYI0Meta || {},
    alias: index8Wx8VjfYI0Meta?.alias || [],
    redirect: index8Wx8VjfYI0Meta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: landingoBrqhuQ4hqMeta?.name ?? "landing",
    path: landingoBrqhuQ4hqMeta?.path ?? "/landing",
    meta: landingoBrqhuQ4hqMeta || {},
    alias: landingoBrqhuQ4hqMeta?.alias || [],
    redirect: landingoBrqhuQ4hqMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: logintl35qSNwFpMeta?.name ?? "login",
    path: logintl35qSNwFpMeta?.path ?? "/login",
    meta: logintl35qSNwFpMeta || {},
    alias: logintl35qSNwFpMeta?.alias || [],
    redirect: logintl35qSNwFpMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: managegQsVP3s34OMeta?.name ?? "manage",
    path: managegQsVP3s34OMeta?.path ?? "/manage",
    meta: managegQsVP3s34OMeta || {},
    alias: managegQsVP3s34OMeta?.alias || [],
    redirect: managegQsVP3s34OMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/manage.vue").then(m => m.default || m),
    children: [
  {
    name: calendaru9ET4GOXsvMeta?.name ?? "manage-calendar",
    path: calendaru9ET4GOXsvMeta?.path ?? "calendar",
    meta: calendaru9ET4GOXsvMeta || {},
    alias: calendaru9ET4GOXsvMeta?.alias || [],
    redirect: calendaru9ET4GOXsvMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/manage/calendar.vue").then(m => m.default || m)
  },
  {
    name: clientswP5AVjTiFGMeta?.name ?? "manage-clients",
    path: clientswP5AVjTiFGMeta?.path ?? "clients",
    meta: clientswP5AVjTiFGMeta || {},
    alias: clientswP5AVjTiFGMeta?.alias || [],
    redirect: clientswP5AVjTiFGMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/manage/clients.vue").then(m => m.default || m)
  },
  {
    name: inboxugYUTYRUegMeta?.name ?? "manage-inbox",
    path: inboxugYUTYRUegMeta?.path ?? "inbox",
    meta: inboxugYUTYRUegMeta || {},
    alias: inboxugYUTYRUegMeta?.alias || [],
    redirect: inboxugYUTYRUegMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/manage/inbox.vue").then(m => m.default || m)
  },
  {
    name: messagesTXZoIpjTO4Meta?.name ?? "manage-messages",
    path: messagesTXZoIpjTO4Meta?.path ?? "messages",
    meta: messagesTXZoIpjTO4Meta || {},
    alias: messagesTXZoIpjTO4Meta?.alias || [],
    redirect: messagesTXZoIpjTO4Meta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/manage/messages.vue").then(m => m.default || m)
  },
  {
    name: settingst7N6DfIQroMeta?.name ?? "manage-settings",
    path: settingst7N6DfIQroMeta?.path ?? "settings",
    meta: settingst7N6DfIQroMeta || {},
    alias: settingst7N6DfIQroMeta?.alias || [],
    redirect: settingst7N6DfIQroMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/manage/settings.vue").then(m => m.default || m)
  },
  {
    name: zoomcallbackebhly7jQ9YMeta?.name ?? "manage-zoomcallback",
    path: zoomcallbackebhly7jQ9YMeta?.path ?? "zoomcallback",
    meta: zoomcallbackebhly7jQ9YMeta || {},
    alias: zoomcallbackebhly7jQ9YMeta?.alias || [],
    redirect: zoomcallbackebhly7jQ9YMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/manage/zoomcallback.vue").then(m => m.default || m)
  }
]
  },
  {
    name: new0VrHbNZ9A2Meta?.name ?? "new",
    path: new0VrHbNZ9A2Meta?.path ?? "/new",
    meta: new0VrHbNZ9A2Meta || {},
    alias: new0VrHbNZ9A2Meta?.alias || [],
    redirect: new0VrHbNZ9A2Meta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/new.vue").then(m => m.default || m)
  },
  {
    name: onboardingix0G1syiIUMeta?.name ?? undefined,
    path: onboardingix0G1syiIUMeta?.path ?? "/onboarding",
    meta: onboardingix0G1syiIUMeta || {},
    alias: onboardingix0G1syiIUMeta?.alias || [],
    redirect: onboardingix0G1syiIUMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/onboarding.vue").then(m => m.default || m),
    children: [
  {
    name: calendar2viFIzyXA2Meta?.name ?? "onboarding-calendar",
    path: calendar2viFIzyXA2Meta?.path ?? "calendar",
    meta: calendar2viFIzyXA2Meta || {},
    alias: calendar2viFIzyXA2Meta?.alias || [],
    redirect: calendar2viFIzyXA2Meta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/onboarding/calendar.vue").then(m => m.default || m)
  },
  {
    name: callbacknlOdNu3GKDMeta?.name ?? "onboarding-callback",
    path: callbacknlOdNu3GKDMeta?.path ?? "callback",
    meta: callbacknlOdNu3GKDMeta || {},
    alias: callbacknlOdNu3GKDMeta?.alias || [],
    redirect: callbacknlOdNu3GKDMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/onboarding/callback.vue").then(m => m.default || m)
  },
  {
    name: indexVM2AUiDHNdMeta?.name ?? "onboarding",
    path: indexVM2AUiDHNdMeta?.path ?? "",
    meta: indexVM2AUiDHNdMeta || {},
    alias: indexVM2AUiDHNdMeta?.alias || [],
    redirect: indexVM2AUiDHNdMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: locationjWuLSbMldcMeta?.name ?? "onboarding-location",
    path: locationjWuLSbMldcMeta?.path ?? "location",
    meta: locationjWuLSbMldcMeta || {},
    alias: locationjWuLSbMldcMeta?.alias || [],
    redirect: locationjWuLSbMldcMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/onboarding/location.vue").then(m => m.default || m)
  },
  {
    name: scheduleURSW8kAypuMeta?.name ?? "onboarding-schedule",
    path: scheduleURSW8kAypuMeta?.path ?? "schedule",
    meta: scheduleURSW8kAypuMeta || {},
    alias: scheduleURSW8kAypuMeta?.alias || [],
    redirect: scheduleURSW8kAypuMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/onboarding/schedule.vue").then(m => m.default || m)
  },
  {
    name: sessionleA8zahuYVMeta?.name ?? "onboarding-session",
    path: sessionleA8zahuYVMeta?.path ?? "session",
    meta: sessionleA8zahuYVMeta || {},
    alias: sessionleA8zahuYVMeta?.alias || [],
    redirect: sessionleA8zahuYVMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/onboarding/session.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileemlkVyFT1mMeta?.name ?? "profile",
    path: profileemlkVyFT1mMeta?.path ?? "/profile",
    meta: profileemlkVyFT1mMeta || {},
    alias: profileemlkVyFT1mMeta?.alias || [],
    redirect: profileemlkVyFT1mMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: providerjvAE7lb5YnMeta?.name ?? "provider",
    path: providerjvAE7lb5YnMeta?.path ?? "/provider",
    meta: providerjvAE7lb5YnMeta || {},
    alias: providerjvAE7lb5YnMeta?.alias || [],
    redirect: providerjvAE7lb5YnMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/provider.vue").then(m => m.default || m)
  },
  {
    name: searchvd7r2lrCVBMeta?.name ?? "search",
    path: searchvd7r2lrCVBMeta?.path ?? "/search",
    meta: searchvd7r2lrCVBMeta || {},
    alias: searchvd7r2lrCVBMeta?.alias || [],
    redirect: searchvd7r2lrCVBMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/search.vue").then(m => m.default || m)
  },
  {
    name: sitemapMtvTYpiarpMeta?.name ?? "sitemap",
    path: sitemapMtvTYpiarpMeta?.path ?? "/sitemap",
    meta: sitemapMtvTYpiarpMeta || {},
    alias: sitemapMtvTYpiarpMeta?.alias || [],
    redirect: sitemapMtvTYpiarpMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: thankyouc3wepwCEfJMeta?.name ?? "thankyou",
    path: thankyouc3wepwCEfJMeta?.path ?? "/thankyou",
    meta: thankyouc3wepwCEfJMeta || {},
    alias: thankyouc3wepwCEfJMeta?.alias || [],
    redirect: thankyouc3wepwCEfJMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: claimsSs9vFGLxA5Meta?.name ?? "tools-claims",
    path: claimsSs9vFGLxA5Meta?.path ?? "/tools/claims",
    meta: claimsSs9vFGLxA5Meta || {},
    alias: claimsSs9vFGLxA5Meta?.alias || [],
    redirect: claimsSs9vFGLxA5Meta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/tools/claims.vue").then(m => m.default || m)
  },
  {
    name: providerssnNtFINy6VMeta?.name ?? "tools-providers",
    path: providerssnNtFINy6VMeta?.path ?? "/tools/providers",
    meta: providerssnNtFINy6VMeta || {},
    alias: providerssnNtFINy6VMeta?.alias || [],
    redirect: providerssnNtFINy6VMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/tools/providers.vue").then(m => m.default || m)
  },
  {
    name: usersG0y3Own4RrMeta?.name ?? "tools-users",
    path: usersG0y3Own4RrMeta?.path ?? "/tools/users",
    meta: usersG0y3Own4RrMeta || {},
    alias: usersG0y3Own4RrMeta?.alias || [],
    redirect: usersG0y3Own4RrMeta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/tools/users.vue").then(m => m.default || m)
  },
  {
    name: yourprivacy98sIRprMt3Meta?.name ?? "yourprivacy",
    path: yourprivacy98sIRprMt3Meta?.path ?? "/yourprivacy",
    meta: yourprivacy98sIRprMt3Meta || {},
    alias: yourprivacy98sIRprMt3Meta?.alias || [],
    redirect: yourprivacy98sIRprMt3Meta?.redirect,
    component: () => import("/home/runner/work/web/web/pages/yourprivacy.vue").then(m => m.default || m)
  }
]