import type { User as FirebaseUser } from 'firebase/auth';
import type UserLocation from '~/types/UserLocation';
import { useTimeoutFn } from '@vueuse/core';
import type User from '~/types/User';
import { useApiFetch } from '~/composables/useApi';

export const useFirebaseUser = () =>
  <Ref<FirebaseUser | null>>useState('firebaseUser', () => null);
export const useFirebaseUserLoading = () =>
  <Ref<boolean>>useState('firebaseUserLoading', () => true);

export const useUser = () => <Ref<User | null>>useState('user', () => null);

export const useFetchUser = async () => {
  const firebaseUser = useFirebaseUser();
  const { data, error } = await useApiFetch('/user', {
    headers: {
      Authorization: `Bearer ${await firebaseUser.value?.getIdToken()}`,
    },
  });

  if (error.value) {
    console.error('Failed fetching user session', error.value);
    return;
  }

  if (!data.value) {
    console.log('No user session');
    return;
  }

  useUser().value = data.value;
};

export const useNotificationBadge = () =>
  <Ref<number | null>>useState('notificationBadge', () => null);

export const useFetchNotificationBadge = async () => {
  const user = useUser();
  if (!user.value) return;

  const { data } = await useApiFetch('/notification/badge');
  useNotificationBadge().value = data.value.count;
};

export const useCheckAuth = async () => {
  const firebaseUser = useFirebaseUser();
  // this will automatically renew the token if needed
  const idTokenCookie = useCookie('idt', {
    secure: true,
  });
  const idToken = await firebaseUser.value?.getIdToken();
  idTokenCookie.value = idToken;
};

const useLocationFromHeader = () => {
  const userLocation = ref<UserLocation>({
    locality: 'New York',
    latitude: 40.7644991,
    longitude: -73.9693359,
    ip: null,
    isPrecise: false,
    zipcode: null,
  });
  if (import.meta.server) {
    const ul = useCookie('ul');
    if (ul.value) {
      userLocation.value = JSON.parse(atob(ul.value));
      return userLocation;
    }
    const headers = useRequestHeaders();
    const geo = headers['cf-iplatitude']
      ? [headers['cf-iplatitude'], headers['cf-iplongitude']]
      : [40.7644991, -73.9693359];

    userLocation.value = {
      locality: decodeURIComponent(headers['cf-ipcity'] || 'New York'),
      latitude: Number(geo[0]),
      longitude: Number(geo[1]),
      ip: headers['cf-connecting-ip'] || null,
      isPrecise: false,
      zipcode: null,
    };
  }
  return userLocation;
};

export const useUserLocation = () =>
  <Ref<UserLocation>>(
    useState('userLocation', () => useLocationFromHeader().value)
  );

export const useSetUserLocation = (location: UserLocation) => {
  const userLocation = useUserLocation();
  if (location.isPrecise) {
    const ul = useCookie('ul');
    ul.value = btoa(JSON.stringify(location));
  }
  userLocation.value = location;
};

export const useToast = () => <Ref<any>>useState('toast', () => null);

export const useSetToast = (toast: any) => {
  const t = useToast();
  t.value = toast;

  useTimeoutFn(() => {
    t.value = null;
  }, 3000);
};
