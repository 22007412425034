
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "cdnURL": "https://static.myproviders.org/static/cf60bc3bd40a353850bd67256018f5c58b9058e3",
  "version": "cf60bc3bd40a353850bd67256018f5c58b9058e3",
  "env": "prod",
  "firebase": {
    "apiKey": "AIzaSyBGm_Q6afNQMn3iuwTGCMfdqJbZI2qh55I",
    "authDomain": "id.myproviders.org",
    "projectId": "myproviders",
    "storageBucket": "myproviders.appspot.com",
    "messagingSenderId": "732261819013",
    "appId": "1:732261819013:web:4f1310f1f8a42421013fec",
    "measurementId": "G-YSBSMGBQJ3"
  },
  "searchIndex": "prod-search-6",
  "apiHost": "https://api.myproviders.org",
  "nuxt": {
    "buildId": "570e4202-594f-4597-b9b5-79022ae19d7d"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
