import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useAppConfig();

  // Initialize Firebase
  const app = initializeApp(config.firebase);

  initUser();

  const auth = getAuth();

  if (process.env.NODE_ENV === 'production') {
    getAnalytics();
  }

  nuxtApp.vueApp.provide('auth', auth);
  nuxtApp.provide('auth', auth);
});
