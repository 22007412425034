import { datadogRum } from '@datadog/browser-rum';
import { useCookieConsent } from '~/utils/compliance';

export default defineNuxtPlugin((nuxtApp) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const config = useAppConfig();

  const cookieConsent = useCookieConsent();

  datadogRum.init({
    applicationId: '13b579da-7642-45de-8213-bf9a7058a9b9',
    clientToken: 'pubaf2265dae03036a517e60ec4d3d0efb0',
    site: 'us5.datadoghq.com',
    service: 'myproviders',
    env: config.env,
    version: config.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      'https://www.myproviders.org',
      'https://api.myproviders.org',
      'http://localhost',
      'https://staging.myproviders.org',
    ],
    trackingConsent: cookieConsent ? 'granted' : 'not-granted',
    enablePrivacyForActionName: true,
  });

  document.addEventListener('cookieyes_banner_load', function (eventData) {
    datadogRum.setTrackingConsent(
      useCookieConsent() ? 'granted' : 'not-granted',
    );
  });

  document.addEventListener('cookieyes_consent_update', function (eventData) {
    datadogRum.setTrackingConsent(
      useCookieConsent() ? 'granted' : 'not-granted',
    );
  });
});
