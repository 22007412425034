export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path === '/terms') {
    return navigateTo(
      'https://support.myproviders.org/terms/terms-and-conditions',
      { external: true },
    );
  } else if (to.path === '/privacy') {
    return navigateTo('https://support.myproviders.org/terms/privacy-policy', {
      external: true,
    });
  }
});
