import { createFetch, type FetchRequest, type FetchOptions } from 'ofetch';

export default defineNuxtPlugin(() => {
  function customFetch<
    T = unknown,
    R extends FetchRequest = FetchRequest,
    O extends FetchOptions = FetchOptions,
  >(request: R, opts: O) {
    return <Promise<T>>createFetch({ fetch })(request, opts); // Optionally configure fetch defaults here
  }

  return {
    provide: {
      customFetch: customFetch,
    },
  };
});
