import type { UseFetchOptions } from 'nuxt/app';

const getIdToken = async () => {
  const idTokenCookie = useCookie('idt', {
    secure: true,
  });

  let idToken = idTokenCookie.value;
  if (import.meta.client) {
    idToken = await useFirebaseUser().value?.getIdToken();
  }

  return idToken;
};

export const useApiFetch = async <T>(
  req: string | (() => string),
  opts: UseFetchOptions<T> = {},
) => {
  return useFetch(`${useAppConfig().apiHost}${req}`, {
    ...opts,
    async onRequest({ request, options }) {
      const idToken = await getIdToken();

      if (idToken) {
        options.headers = [['Authorization', `Bearer ${idToken}`]];
      }
    },
    $fetch: useNuxtApp().$customFetch,
  });
};

export const useApiRequest = async <T>(
  req: string | (() => string),
  opts: UseFetchOptions<T> = {},
) => {
  const idToken = await getIdToken();

  if (!opts) {
    opts = {};
  }

  return useNuxtApp().$customFetch(`${useAppConfig().apiHost}${req}`, {
    ...opts,
    headers: idToken
      ? {
          Authorization: `Bearer ${idToken}`,
        }
      : {},
  });
};

export const useLegacyApiFetch = <T>(
  url: string | (() => string),
  options: UseFetchOptions<T> = {},
) => {
  return useFetch(url, {
    ...options,
    $fetch: useNuxtApp().$customFetch,
  });
};
